import React from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
// import { connect } from 'react-redux';

// import { makeStyles } from '@material-ui/core/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import CloseIcon from '@mui/icons-material/Close';

import AuthService from '../../../../../services/authService';
import '../../SidebarStyle.scss';

/* const useStyles = makeStyles(theme => ({
	root: {
	},
	listItem: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	listItemIcon: {
		minWidth: 'auto',
	},
	listItemLink: {
		textDecoration: 'none',
	},
	closeIcon: {
		justifyContent: 'flex-end',
		cursor: 'pointer',
	},
	divider: {
		width: '100%',
	},
	countCircle: {
		background: theme.palette.secondary.main,
		borderRadius: '50%',
		marginLeft: theme.spacing(1),
		padding: theme.spacing(0.3, 1),
	},
})); */

const SidebarNav = ({ pages, onClose, className, history, ...rest }) => {
	// const { pages, onClose, className, cartCount, ...rest } = pr	ops;
  const navigate = useNavigate();
	const classes = {};

	const logoutUser = () => {
		AuthService.logout();
		localStorage.clear();
		navigate('/');
	};

	const handleClickRelief = () => {
		const path = AuthService.checkToken() ? '/loan' : '/sign-in';
		navigate(path);
	};

	/* const handleNavigation = (product) => {
		onClose();
		if (product) {
			switch (product.label) {
				default										:	return history.push('/starmap');
			}
		}
	}; */

	// <List {...rest} className={clsx(classes.root, className)}>

	return (
		<List className={`nav-root ${className}`}>
			<ListItem className={classes.closeIcon} onClick={onClose}>
				<ListItemIcon className={classes.listItemIcon}>
					<CloseIcon fontSize="small" />
				</ListItemIcon>
			</ListItem>
			<ListItem className={classes.listItem} onClick={onClose}>
				<Typography
					variant="h6"
					color="primary"
					component="a"
					// href="/sign-in"
					className={classes.listItemLink}
					onClick={handleClickRelief}
				>
					Get Rent Relief
				</Typography>
			</ListItem>

			{/* {products.map((singleProduct, index) => (
				<ListItem className={classes.listItem} key={index}>
					<Typography						
						variant="h6"
						color="primary"
						component="a"
						// href="/home"
						className={classes.listItemLink}
						onClick={() => handleNavigation(singleProduct)}
					>
						{singleProduct.name}
					</Typography>
				</ListItem>
			))} */}
			<Divider className='divider'/>

			{AuthService.checkToken() ? (
				<div>
					<ListItem className={classes.listItem} onClick={onClose}>
						<Typography
							variant="h6"
							color="primary"
							className={classes.listItemLink}
							component={Link}
							to="/profile"
						>
							My Profile
						</Typography>
					</ListItem>
					{/* <ListItem className={classes.listItem} onClick={onClose}>
						<Typography
							variant="h6"
							color="primary"
							className={classes.listItemLink}
							component={Link}
							to="/cart"
						>
							My Cart
						</Typography>
					</ListItem> */}
					<ListItem className={classes.listItem} onClick={onClose}>
						<Typography
							variant="h6"
							color="primary"
							className={classes.listItemLink}
							component={Link}
							to="/applications"
						>
							My Applications
						</Typography>
					</ListItem>
					<ListItem className={classes.listItem} onClick={onClose}>
						<Typography
							variant="h6"
							color="primary"
							component="a"
							// href="/signin"
							className={classes.listItemLink}
							onClick={logoutUser}
						>
							Logout
						</Typography>
					</ListItem>
				</div>
			) : (
				<ListItem className={classes.listItem} onClick={onClose}>
					<Typography
						variant="h6"
						color="primary"
						// component="a"
						// href="/signin"
						className={classes.listItemLink}
						component={Link}
						to="/sign-in"
					>
						Sign In
					</Typography>
				</ListItem>
			)}

		</List>
	);
};

SidebarNav.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	// pages: PropTypes.object.isRequired,
	// products: PropTypes.array.isRequired,
};

/* const mapStateToProps = state => {
	return { cartCount: state.cartCount };
};
 */
export default SidebarNav;
