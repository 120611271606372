import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MainLayout } from "./layouts";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

import {
	Home,
	NotFound,
	Auth,
	Profile,
	Applications,
	ApplicationDetails,
	UploadDocument,
	ForgotPassword,
	ResetPassword,
	Loan,
	Terms,
	PrivacyPolicy
} from "./views";

const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<MainLayout />}>
					<Route path="/" element={<Home />} />
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/documents" element={<AuthenticatedRoute> <UploadDocument /> </AuthenticatedRoute>} />
					<Route path="/profile" element={<AuthenticatedRoute> <Profile /> </AuthenticatedRoute>} />
					<Route path="/loan" element={<AuthenticatedRoute> <Loan /> </AuthenticatedRoute>} />
					<Route path="/applications" element={<AuthenticatedRoute> <Applications /> </AuthenticatedRoute>} />
					<Route path="/applications/:id" element={<AuthenticatedRoute> <ApplicationDetails /> </AuthenticatedRoute>} />
					<Route
						path="/sign-in"
						element={<UnauthenticatedRoute> <Auth /> </UnauthenticatedRoute>}
					/>
					<Route
						path="/forgot-password"
						element={<UnauthenticatedRoute> <ForgotPassword /> </UnauthenticatedRoute>}
					/>
					<Route
						path="/reset-password/:hash"
						element={<UnauthenticatedRoute> <ResetPassword /> </UnauthenticatedRoute>}
					/>
					{/* <Route path="/sign-in" component={<SignIn />} /> */}
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;