import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import { styled } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

const PaperContainer = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
	alignItems: 'center',
	padding: theme.spacing(1),
	display: 'flex',
	flexBasis: 420
  // [theme.breakpoints.down('sm')]: { margin: '16px' },
}));

const SearchIconStyled = styled(SearchIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
	color: theme.palette.text.secondary
}));

const InputStyled = styled(Input)(({ theme }) => ({
  flexGrow: 1,
	fontSize: 14,
	lineHeight: theme.spacing(2),
	letterSpacing: -0.05
}));

const SearchInput = props => {
	const { className, onChange, style, ...rest } = props;
	
	return (
		<PaperContainer
			{...rest}
		>
			<SearchIconStyled />
			<InputStyled
				{...rest}
				disableUnderline
				onChange={onChange}
			/>
		</PaperContainer>
	);
};

SearchInput.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	style: PropTypes.object
};

export default SearchInput;
	