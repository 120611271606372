import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import validate from 'validate.js';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';

import './ResetPasswordStyle.scss';
import '../../App.css';
import API from "../../services/axiosApi";
import { COMMON_ERR_MSG, APP_NAME } from "../../config";


const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 300,
		},
	},
	password: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128,
			minimum: 8,
		},
	},
	confirmPassword: {
		presence: { allowEmpty: false, message: 'is required' },
		equality: {
			attribute: "password",
			message: "^Password and Confirm Password doesn't match"
		}
	},
};

const ResetPassword = () => {
  const navigate = useNavigate();
	const { hash } = useParams();

	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [ success, setSuccess ] = useState({ status: false, message: 'asd' });
	const [ isValidLink, setValidLink ] = useState(true);

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {},
		}));
	}, [formState.values]);

	useEffect(() => {
		const checkUrl = async () => {
			setLoading(true);
			try {
				const response = await API.get('password/' + hash);
				setLoading(false);
				if (response.data.success) {
					setValidLink(true)
					setFormState(formState => ({
						...formState,
						values: {
							...formState.values,
							email: response.data.data.user.email
						},
					}));
					// setSuccessMessage(response.data.message);
					// props.history.push('/');
				} else {
					setValidLink(false);
				}
			} catch (error) {
				setLoading(false);
				setValidLink(false)
			}
		};
		checkUrl();
	}, [hash]);

	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
					event.target.type === 'checkbox'
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...formState.touched,
				[event.target.name]: true,
			},
		}));
	};

	/**
	 * Hanlde sign-in
	 * 
	 * @param {Object} formData 
	 */
	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(false);
		setErrorMessage('');
		if (!formState.isValid) { return; }
		try {
			setLoading(true);
			const postData = { ...formState.values, hash }
			const response = await API.post('password/reset', postData);
			setLoading(false);
			if (response.data.success) {
				setSuccess({ status: true, message: response.data.message });
			} else {
				const errorMsg = response.data.message || 'Invalid Credentials';
				setErrorMessage(errorMsg);
			}
		} catch (error) {
			setLoading(false);
			const errMsg = (error.response && error.response.data) ? error.response.data.message : COMMON_ERR_MSG;
			setErrorMessage(errMsg);
		}
	};


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	const handleRedirect = (event, path) => {
		event.preventDefault();
		navigate(path);
		window.scrollTo(0, 0);
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	return (
		<div className="reset-screen">

			<div className="reset-form-container">
				<div className="form-header">
					<Typography variant='h5' className="text-bold" align='center'>
						Reset Password
					</Typography>
					{/* <Typography variant="subtitle1" align='center'>
						We will send you an email with instructions on how to reset your password.
					</Typography> */}
				</div>
				{errorMessage &&
					<Alert className="error-alert" severity="error">{ errorMessage }</Alert>
				}
				{isValidLink ? (
					<>
					{success.status ? (
						<Typography variant="subtitle1" className="success-message">
							<Alert severity="success">{ success.message }</Alert>
							<Link href='' onClick={(event) => handleRedirect(event, '/sign-in')} variant="body2" className="redirection-link">
								Click here to return to <b>{APP_NAME}</b> Sign In Page
							</Link>
						</Typography>
					) : (
						<form name="reset-form" className="reset-form" method="post" onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										placeholder="E-mail"
										label="E-mail"
										variant="filled"
										size="medium"
										name="email"
										fullWidth
										helperText={hasError('email') ? formState.errors.email[0] : null}
										error={hasError('email')}
										onChange={handleChange}
										type="email"
										value={formState.values.email || ''}
										required
										autoComplete="email"
										disabled
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										placeholder="Password"
										label="Password"
										autoComplete="current-password"
										variant="filled"
										size="medium"
										name="password"
										fullWidth
										helperText={
											hasError('password') ? formState.errors.password[0] : null
										}
										error={hasError('password')}
										onChange={handleChange}
										type="password"
										value={formState.values.password || ''}
										required
										autoFocus
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										placeholder="Confirm Password"
										label="Confirm Password"
										autoComplete="new-password"
										variant="filled"
										size="medium"
										name="confirmPassword"
										fullWidth
										helperText={
											hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null
										}
										error={hasError('confirmPassword')}
										onChange={handleChange}
										type="password"
										value={formState.values.confirmPassword || ''}
										required
									/>
								</Grid>

								{/*
								<Grid item xs={12}>
									<TextField
										className={classes.textField}
										error={hasError('confirmPassword')}
										fullWidth
										helperText={
											hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null
										}
										label="Confirm Password"
										name="confirmPassword"
										onChange={handleChange}
										type="password"
										value={formState.values.confirmPassword || ''}
										variant="outlined"
										autoComplete="new-password"
										required
									/>
								</Grid> */}

								<Grid item xs={12}>
									<i>
										<Typography variant="subtitle2">
											Fields that are marked with * sign are required.
										</Typography>
									</i>
								</Grid>
								<Grid item xs={12} alignItems="flex-end" className='action-btn-container'>
									<LoadingButton
										loading={loading}
										size="large"
										variant="contained"
										type="submit"
										color="primary"
										disabled={loading}
										disableElevation
									>
										Reset
									</LoadingButton>
								</Grid>
							</Grid>
						</form>
					)}
					</>
				) : (
					<div className="success-message">
						<Alert severity="warning">This link is expired</Alert>
						<Link href='' className="redirection-link" variant="body2" onClick={(event) => handleRedirect(event, '/forgot-password')}>
							Reset you password again
						</Link>
					</div>
				)}

			</div>
	
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};


export default ResetPassword;
