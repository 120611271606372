import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import './UploadDocumentFormStyle.scss';
// import API from "../../services/axiosApi";
import { COMMON_ERR_MSG } from "../../config";


const maxUploadSize = 5; // In MB
const byteToMb = 1000000; //  1MB = 1000000 Bytes = 1000 * 1000 (in decimal format)


const UploadDocumentForm = ({ loading, onSnackToogle, onBack, onFormSubmit }) => {
	const [uploadedFiles, setUploadFiles] = useState({});
	const [invalidFiles, setInvalidFiles] = useState({});


	/**
	 * Check if upload file have valid type
	 * 
	 * @param {Object} file 
	 * @returns {Boolean}
	 */
	const isValidFileUploaded = (file) => {
		const validExtensions = ['png', 'jpeg', 'jpg', 'pdf'];
		const fileExtension = file.type.split('/')[1];
		return validExtensions.includes(fileExtension);
	};
	

	/**
	 * Handle file change
	 * 
	 * @param {*} e 
	 */
	const handleFileChange = async (e, fieldName) => {

		const file = e.target.files[0];
		if ( !file ) { return; }
		const fileSize = parseInt(file.size);

		if(fileSize > maxUploadSize * byteToMb) { // Number of MegaBytes;
			return onSnackToogle("Document Size Shouldn't Exceed " + maxUploadSize + "MB");
		}

		if(!isValidFileUploaded(file)) {
			return onSnackToogle("Document should be either an image or PDF");
		}

		setUploadFiles(uploadedFiles => ({ ...uploadedFiles, [fieldName]: file }));
		setInvalidFiles(invalidFiles => ({ ...invalidFiles, [fieldName]: '' }));
	};

	const validateAll = async () => {
		let invalid = { ...invalidFiles }, isInvalid = false;
		if (!uploadedFiles.lease) { invalid.lease = 'Lease document is required'; isInvalid = true; };
		// if (!uploadedFiles.impactLetter) { invalid.impactLetter = 'Impact letter is required'; isInvalid = true; };
		// if (!uploadedFiles.rentInvoice) { invalid.rentInvoice = 'Rent invoice is required'; isInvalid = true; };
		// if (!uploadedFiles.securityDeposit) { invalid.securityDeposit = 'Security deposit document is required'; isInvalid = true; };
		// if (!uploadedFiles.utilityBills) { invalid.utilityBills = 'Utility bills is required'; isInvalid = true; };
		// if (!uploadedFiles.internetInvoiceStatement) { invalid.internetInvoiceStatement = 'Internet invoice statement is required'; isInvalid = true; };
		// if (!uploadedFiles.dueRentStatement) { invalid.dueRentStatement = 'Due rent statement is required'; isInvalid = true; };
		// if (!uploadedFiles.additionalInformation) { invalid.additionalInformation = 'Additional information is required'; isInvalid = true; };
		return { invalid, isInvalid };
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const { invalid, isInvalid } = await validateAll();
			setInvalidFiles(invalid);
			if (!isInvalid) {
				onFormSubmit(uploadedFiles);
			}
		} catch (error) {
			console.log("ERROR in handleSubmit : ",error);
			const errMsg = error?.response?.data?.message || COMMON_ERR_MSG;
			onSnackToogle(errMsg);
		}
	};

	return (
		<>
			<Paper className="loan-form-paper" elevation={2}>
				{ loading ? <LinearProgress className="progress-bar" /> : null }
				<div className="loan-documents-form">
					<div className="form-container">
					<form name="document-form" className="document-form" method="post" onSubmit={handleSubmit}>
						<Grid container spacing={2}>
							<DocumentComponent
								title="Property Management Lease"
								fieldName="lease"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.lease?.name || ''}
								error={invalidFiles.lease}
							/>
							<DocumentComponent
								title="COVID 19 Impact Letter"
								fieldName="impactLetter"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.impactLetter?.name || ''}
							/>
							<DocumentComponent
								title="Rent Invoice"
								fieldName="rentInvoice"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.rentInvoice?.name || ''}
							/>
							<DocumentComponent
								title="Security Deposit"
								fieldName="securityDeposit"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.securityDeposit?.name || ''}
							/>
							<DocumentComponent
								title="Utility Bills"
								fieldName="utilityBills"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.utilityBills?.name || ''}
							/>
							<DocumentComponent
								title="Internet Invoice Statement"
								fieldName="internetInvoiceStatement"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.internetInvoiceStatement?.name || ''}
							/>
							<DocumentComponent
								title="Past Due Rent Statement"
								fieldName="dueRentStatement"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.dueRentStatement?.name || ''}
							/>
							<DocumentComponent
								title="Additional Information"
								fieldName="additionalInformation"
								onFileChange={handleFileChange}
								fileName={uploadedFiles.additionalInformation?.name || ''}
							/>
						
							{/* <Grid item xs={12} alignItems="flex-end" className='action-btn-container'>
								<Button
									size="large"
									variant="contained"
									type="submit"
									color="primary"
									// disabled={loading}
									disableElevation
									className='upload-submit-button'
								>
									Data Upload
								</Button>
							</Grid> */}
						</Grid>
					</form>
					</div>
				</div>
			</Paper>

			<div className="footer-action">
				<Button
					color="inherit"
					onClick={onBack}
					sx={{ mr: 1 }}
					variant="outlined"
				>
					Back
				</Button>
				<Button type="button" variant="contained" onClick={handleSubmit} disabled={loading}>
					Upload
				</Button>
			</div>
		</>
	);
};

const DocumentComponent = ({ title, fileName, fieldName, onFileChange, error }) => {
	return (
		<Grid item xs={12} md={6}>
			<div className='upload-file-item'>
				<div className={fileName ? 'field-box uploaded' : 'field-box'}>
					<Typography className='text-bold'>{title}</Typography>
					<Typography variant='subtitle2'>{fileName}</Typography>
				</div>
				<input
					accept="image/jpeg, image/jpg, image/png, image/x-png, application/pdf, image/x-eps"
					className="upload-file"
					id={`button-file-${fieldName}`}
					type="file"
					onChange={(event) => onFileChange(event, fieldName)}
				/>
				<label htmlFor={`button-file-${fieldName}`}>
					<Button
						variant="contained"
						startIcon={<FileUploadIcon className='upload-icon' />} 
						disableElevation
						className='upload-btn'
						classes={{ startIcon: 'upload-icon-container' }}
						component="span"
					/>
				</label>
			</div>
			<Typography color="error" variant='subtitle2' className='doc-error'>{error}</Typography>
		</Grid>
	);
};

UploadDocumentForm.propTypes = {
	setLoading: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	onBack: PropTypes.func.isRequired,
	onSnackToogle: PropTypes.func.isRequired,
};

export default UploadDocumentForm;