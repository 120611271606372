import React, { useEffect, useState, useMemo } from 'react';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import API from "../../services/axiosApi";
import './ApplicationDetailStyle.scss';
import { COMMON_ERR_MSG, SERVER_PATH, APPLICATIONS_PATH } from '../../config';
import { formatDate, formatCurrency, formatUnderscore } from '../../utils/formatter';
import TabPanel from '../../components/TabPanel';
// import { updateCartCount, decrementCartCount, onUserProfileUpdate } from '../../redux/actions';


const ApplicationDetails = () => {
	const navigate = useNavigate();
	const { id: appId } = useParams();

	const [loading, setLoading] = useState(false);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [application, setApplication] = useState({});
	const [tabValue, setTabValue] = useState('details');

	// const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));


	useEffect(() => {
		const fetchApplicationDetails = async () => {
			setLoading(true);
			try {
				const response = await API.get('applications/' + appId);
				if (response.data.success) {
					if (!response.data.data.applicationDetails) {
						handleSnackToogle("Record for this ID don't exists!");
						return navigate(-1);
					}
					const applicationDetails = response.data?.data?.applicationDetails ? response.data.data.applicationDetails : {};
					setApplication(applicationDetails);
				} else {
					handleSnackToogle(response.data.message);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchApplicationDetails : ", error);
				setLoading(false);
				const errorMsg = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : COMMON_ERR_MSG;
				handleSnackToogle(errorMsg);
			}
		};
		fetchApplicationDetails();
	}, [navigate, appId]);

	const repaymentSchedule = useMemo(() => {
		if (!application?.id || application?.status !== 'approved') { return []; }
		let schedule = [];
		const amountPerMonth = formatCurrency(application.loanTotal / application.loanTerm);
		for (let i = 1; i < application.loanTerm; i++) {
			schedule = [ ...schedule, {
				date: moment(application.approvedAt).add(i, 'months').format("LL"),
				amount: amountPerMonth
			}];
		}
		return schedule;
	}, [application]);


	const getChipColor = (appStatus) => {
		let color = '#f8c528';
		switch (appStatus) {
			case 'approved'		: color = '#4CAF4F';	break;
			case 'rejected'		: color = '#D43C30';	break;
			default						: color = '#F8C528';	break;
		}
		return color;
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	return (		
		<div className="applications-details">
			
			<Container className="content">
				<div className="pageTitle">
					<div className="title-container">
						<IconButton className='back-btn' size="small" aria-label="go-back" onClick={() => navigate(-1) }>
							<ArrowBackIcon />
						</IconButton>
						<Typography variant="h5" className='text-bold'>Application #{application.loanNumber || ''}</Typography>
					</div>
					<Chip className="status-chip" style={{ backgroundColor: getChipColor(application.status) }} label={formatUnderscore(application.status)} />				
				</div>
				<Paper className="paper">
					{ loading ? <LinearProgress className="progress-bar" /> : null }
					<Tabs value={tabValue} onChange={handleTabChange}>
						<Tab label="Loan Details" value="details" />
						{application.status === "approved" && <Tab label="Schedule" value="schedule" /> }						
					</Tabs>
					<TabPanel value={tabValue} index="details">
						<DetailsTab application={application} />
					</TabPanel>
					{application.status === "approved" && 
						<TabPanel value={tabValue} index="schedule" padding={0}>
							<ScheduleTab repaymentSchedule={repaymentSchedule} />
						</TabPanel>
					}
				</Paper>
			</Container>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={2000}
			/>
		</div>
	);
};

const DetailsTab = ({ application }) => {
	return (
		<>
			<Grid
				container
				spacing={3}
				className="content-grid"
			>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Loan Number</Typography>
					<Typography>{application?.loanNumber || '-'}</Typography>
				</Grid>
				
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Rent Amount</Typography>
					<Typography>${application.user?.rentAmount ? formatCurrency(application.user?.rentAmount) : '-'}</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Loan Principal</Typography>
					<Typography>${application.loanPrincipal ? formatCurrency(application.loanPrincipal) : '-'}</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Loan Interest</Typography>
					<Typography>{application.loanInterest + '%'}</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Loan Interest Amount</Typography>
					<Typography>${formatCurrency(application.loanInterestAmount)}</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Loan Term (Duration)</Typography>
					<Typography>{application.loanTerm ? application.loanTerm + ' months' : '-'}</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Loan Total</Typography>
					<Typography>${application.loanTotal ? formatCurrency(application.loanTotal) : '-'}</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Submitted At</Typography>
					<Typography>{formatDate(application.createdAt)}</Typography>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography variant='h6'>Status</Typography>
					<Typography>{formatUnderscore(application.status)}</Typography>
				</Grid>
				{application.status !== "pending" && 
					<Grid item md={6} xs={12}>
						<Typography variant='h6'>Note</Typography>
						<Typography>{application?.note || '-'}</Typography>
					</Grid>
				}
				<Grid item md={12} xs={12}>
					<Typography variant='subtitle2' className='title'>Application Documents</Typography>
					<ApplicationFileComponent title="Property Management Lease" fileName={application.leaseFile} />
					{application.impactLetterFile &&
						<ApplicationFileComponent title="COVID 19 Impact Letter" fileName={application.impactLetterFile} /> }
					{application.impactLetterFile &&
						<ApplicationFileComponent title="Rent Invoice" fileName={application.rentInvoiceFile} /> }
					{application.impactLetterFile &&
						<ApplicationFileComponent title="Security Deposit" fileName={application.securityDepositFile} /> }
					{application.impactLetterFile &&
						<ApplicationFileComponent title="Utility Bills" fileName={application.utilityBillsFile} /> }
					{application.impactLetterFile &&
						<ApplicationFileComponent title="Internet Invoice Statement" fileName={application.internetInvoiceFile} /> }
					{application.impactLetterFile &&
						<ApplicationFileComponent title="Past Due Rent Statement" fileName={application.dueRentStatementFile} /> }
					{application.impactLetterFile &&
						<ApplicationFileComponent title="Additional Information" fileName={application.additionalInfoFile} /> }
				</Grid>
			</Grid>
		</>
	);
};

const ScheduleTab = ({ repaymentSchedule }) => {
	return (
      <Table className='schedule-table'>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {repaymentSchedule.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{row.date}</TableCell>
              <TableCell align="right">${row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
	);
};

const ApplicationFileComponent = ({ fileName, title }) => {
	const appAssetPath = SERVER_PATH + APPLICATIONS_PATH;
	return (
		<a href={appAssetPath + fileName} target="_blank" rel="noopener noreferrer" className='file-link' >
			<Typography variant='subtitle1'>{title}</Typography> <OpenInNewIcon className="file-open-icon" />
		</a>
	);
};



export default ApplicationDetails;