import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import AppRoutes from './AppRoutes';
import theme from './theme';

const App = () => {

	window.onbeforeunload = function () {
		window.scrollTo(0, 0);
	}

	return (
		<ErrorBoundary className="App">
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<AppRoutes />
			</ThemeProvider>
		</ErrorBoundary>
	);
}

export default App;