import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import validate from 'validate.js';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';

import './ForgotPasswordStyle.scss';
import '../../App.css';
import API from "../../services/axiosApi";
import { COMMON_ERR_MSG, APP_NAME } from "../../config";


const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 300,
		},
	},
};

const ForgotPassword = () => {
  const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [ success, setSuccess ] = useState({ status: false, message: 'asd' });

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {},
		}));
	}, [formState.values]);

	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
					event.target.type === 'checkbox'
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...formState.touched,
				[event.target.name]: true,
			},
		}));
	};

	/**
	 * Hanlde sign-in
	 * 
	 * @param {Object} formData 
	 */
	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(false);
		setErrorMessage('');
		try {
			setLoading(true);
			const response = await API.post('forgot_password', formState.values);
			setLoading(false);
			if (response.data.success) {
				setSuccess({ status: true, message: response.data.message });
				// handleSnackToogle(response.data.message);
				// navigate('/reset-password');
				// window.scrollTo(0, 0);
			} else {
				const errorMsg = response.data.message || 'Invalid Credentials';
				setErrorMessage(errorMsg);
			}
		} catch (error) {
			console.log("ERROR in handleSignUp : ", error);
			setLoading(false);
			const errMsg = (error.response && error.response.data) ? error.response.data.message : COMMON_ERR_MSG;
			setErrorMessage(errMsg);
		}
	};


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	const handleRedirect = (event) => {
		event.preventDefault();
		navigate('/sign-in');
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	return (
		<div className="forgot-screen">

			<div className="forgot-form-container">
				<div className="form-header">
					<Typography variant='h5' className="text-bold" align='center'>
						Forgot Password
					</Typography>
					<Typography variant="subtitle1" align='center'>
						We will send you an email with instructions on how to reset your password.
					</Typography>
				</div>
				{errorMessage &&
					<Alert className="error-alert" severity="error">{ errorMessage }</Alert>
				}
				{success.status ? (
					<Typography variant="subtitle1" className="success-message">
						<Alert severity="success">{ success.message }</Alert>
						<Link href='' onClick={handleRedirect} variant="body2" className="redirection-link">
							Click here to return to <b>{APP_NAME}</b> Sign In Page
						</Link>
					</Typography>
				) : (
					<form name="forgot-form" className="forgot-form" method="post" onSubmit={handleSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									placeholder="E-mail"
									label="E-mail"
									variant="filled"
									size="medium"
									name="email"
									fullWidth
									helperText={hasError('email') ? formState.errors.email[0] : null}
									error={hasError('email')}
									onChange={handleChange}
									type="email"
									value={formState.values.email || ''}
									required
									autoComplete="email"
								/>
							</Grid>
							<Grid item xs={12}>
								<i>
									<Typography variant="subtitle2">
										Fields that are marked with * sign are required.
									</Typography>
								</i>
							</Grid>
							<Grid item xs={12} alignItems="flex-end" className='action-btn-container'>
								<LoadingButton
									loading={loading}
									size="large"
									variant="contained"
									type="submit"
									color="primary"
									disabled={loading}
									disableElevation
								>
									Email Me
								</LoadingButton>
							</Grid>
						</Grid>
					</form>
				)}

			</div>
	
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};


export default ForgotPassword;
