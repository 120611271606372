import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { State, City }  from 'country-state-city';
import { Helmet } from "react-helmet";


import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingButton from '@mui/lab/LoadingButton';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import '../AuthStyle.scss';

const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 255,
		},
	},
	name: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 250,
		},
	},
	address: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 255,
		},
	},
	city: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128,
		},
	},
	state: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128,
		},
	},
	zip: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 32,
		},
	},
	apartmentName: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 255,
		},
	},
	rentAmount: {
		presence: { allowEmpty: false, message: 'is required' },
		/* length: {
			maximum: 120,
		}, */
	},
	impactReason: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	password: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128,
			minimum: 8,
		},
	},
	/* password: {
		presence: { allowEmpty: false, message: 'is required' },
		format: {
			pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{4,}$/,
			flags: "i",
			message: "Must contain at-least one uppercase, lowercase, number and a special character."
		},
		length: {
			maximum: 128,
			minimum: 8
		},
	}, */
	/* password_confirmation: {
		presence: true,
		equality: {
			attribute: "password",
			message: "^Password and Confirm Password doesn't match."
		}
	}, */
};

const COUNTRY_CODE_US = 'US';

const SignUpComponent = ({ loading, onSignUp }) => {
  // const navigate = useNavigate();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
  const [stateInputValue, setStateInputValue] = useState('');
  const [cityInputValue, setCityInputValue] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

	useEffect(() => {
		try {
			const statesUS = State.getStatesOfCountry(COUNTRY_CODE_US);
			setStates(statesUS);
		} catch (error) {
			console.log("ERROR in getStatesOfCountry : ", error);
		}
	}, []);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {},
		}));
	}, [formState.values]);

	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
					event.target.type === 'checkbox'
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...formState.touched,
				[event.target.name]: true,
			},
		}));
	};

	/**
	 * Handle state autocomplete change
	 * 
	 * @param {Object} stateValue 
	 */
	const handleStateChange = (stateValue) => {
		// console.log("stateValue : ", stateValue);
		try {
			if (stateValue && stateValue.isoCode) {
				const citiesDetails = City.getCitiesOfState(COUNTRY_CODE_US, stateValue.isoCode);
				// console.log("citiesDetails : ", citiesDetails);
				setCities(citiesDetails);
			}
			setSelectedState(stateValue);
			setFormState(formState => ({
				...formState,
				values: { ...formState.values, state: (stateValue && stateValue.name) ? stateValue.name : '' },
				touched: { ...formState.touched, state: true },
			}));
		} catch (error) {
			console.log("ERROR in handleStateChange : ", error);			
		}
	};

	/**
	 * Handle city autocomplete change
	 * 
	 * @param {Object} cityValue 
	 */
	 const handleCityChange = (cityValue) => {
		try {
			setSelectedCity(cityValue);
			setFormState(formState => ({
				...formState,
				values: { ...formState.values, city: cityValue },
				touched: { ...formState.touched, city: true },
			}));
		} catch (error) {
			console.log("ERROR in handleCityChange : ", error);			
		}
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (formState.isValid) {
			onSignUp(formState.values);
			// window.location.replace('/');
		}
		setFormState(formState => ({
			...formState,
			touched: {
				...formState.touched,
				...formState.errors,
			},
		}));
	};

	/**
	 * Toogle password visiblity
	 */
	const handlePasswordVisibility = () => {
		setFormState({ ...formState, values: { ...formState.values,  showPassword: !formState.values.showPassword } });
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	return (
		<form name="sigin-form" className="sigin-form" method="post" onSubmit={handleSubmit}>
			<Helmet>
				<title>Rent Relief | Helping Americans With Rental Assistance</title>
				<meta name="description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
				<meta name="keywords" content="rent relief, rent relief for americans, help for rent relief, help for rent, refief from rent, how to get relief from rent, housing assistant, emergency rent relief, help to pay rent, covid rent refief,Rent Relief funds, rent assistance,apply for Rent Relief" />
				<meta property="og:title" content="Rent Relief | Helping Americans With Rental Assistance" />
				<meta property="og:description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
			</Helmet>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						placeholder="Name"
						label="Name"
						variant="outlined"
						size="medium"
						name="name"
						fullWidth
						helperText={
							hasError('name') ? formState.errors.name[0] : null
						}
						error={hasError('name')}
						onChange={handleChange}
						type="text"
						value={formState.values.name || ''}
						autoFocus
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="E-mail"
						label="E-mail"
						variant="outlined"
						size="medium"
						name="email"
						fullWidth
						helperText={hasError('email') ? formState.errors.email[0] : null}
						error={hasError('email')}
						onChange={handleChange}
						type="email"
						value={formState.values.email || ''}
						required
						autoComplete="email"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="Password"
						label="Password"
						autoComplete="new-password"
						variant="outlined"
						size="medium"
						name="password"
						fullWidth
						helperText={
							hasError('password') ? formState.errors.password[0] : null
						}
						error={hasError('password')}
						onChange={handleChange}
						type={formState.values.showPassword ? 'text' : 'password'}
						value={formState.values.password || ''}
						required
						InputProps={{
							endAdornment: <InputAdornment position="end"><IconButton
								aria-label="toggle password visibility"
								edge="end"
								onClick={handlePasswordVisibility}
							>
								{formState.values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton></InputAdornment>,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="Address"
						label="Address"
						variant="outlined"
						size="medium"
						name="address"
						fullWidth
						helperText={
							hasError('address') ? formState.errors.address[0] : null
						}
						error={hasError('address')}
						onChange={handleChange}
						type="text"
						value={formState.values.address || ''}
						required
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						freeSolo
						id="city-autocomplete"
						disableClearable
						options={cities.map((option) => option.name)}
						value={selectedCity}
						onChange={(event, newValue) => handleCityChange(newValue)}
						inputValue={cityInputValue}
						onInputChange={(event, newInputValue) => setCityInputValue(newInputValue)}
						fullWidth
						autoSelect
						renderInput={(params) => (
							<TextField {...params}
								label="City"
								variant="outlined"
								placeholder="City"
								name="city"
								size="medium"
								helperText={
									hasError('city') ? formState.errors.city[0] : null
								}
								error={hasError('city')}
								type="text"
								autoComplete='new-password'
								required
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Autocomplete
						value={selectedState}
						onChange={(event, newValue) => handleStateChange(newValue)}
						inputValue={stateInputValue}
						onInputChange={(event, newInputValue) => setStateInputValue(newInputValue)}
						id="state-autocomplete"
						fullWidth
						options={states}
					  renderOption={(props, option) => <li {...props}>{option.name}</li>}
            getOptionLabel={option => option.name}
						renderInput={(params) => (
							<TextField {...params}
								label="State"
								variant="outlined"
								placeholder="State"
								name="state"
								size="medium"
								helperText={
									hasError('state') ? formState.errors.state[0] : null
								}
								error={hasError('state')}
								type="text"
								autoComplete='new-password'
								required
							/>
						)}
					/>
				</Grid>
				<Grid item xs={4}>
					<TextField
						placeholder="Zip"
						label="Zip"
						variant="outlined"
						size="medium"
						name="zip"
						fullWidth
						helperText={
							hasError('zip') ? formState.errors.zip[0] : null
						}
						error={hasError('zip')}
						onChange={handleChange}
						type="text"
						value={formState.values.zip || ''}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="Name of Apartment Complex"
						label="Name of Apartment Complex"
						variant="outlined"
						size="medium"
						name="apartmentName"
						fullWidth
						helperText={
							hasError('apartmentName') ? formState.errors.apartmentName[0] : null
						}
						error={hasError('apartmentName')}
						onChange={handleChange}
						type="text"
						value={formState.values.apartmentName || ''}
						required
						autoComplete='off'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="Unit Number (Optional)"
						label="Unit Number"
						variant="outlined"
						size="medium"
						name="unitNumber"
						fullWidth
						onChange={handleChange}
						type="text"
						value={formState.values.unitNumber || ''}
						autoComplete='off'
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="Rent Amount (in USD)"
						label="Rent Amount"
						variant="outlined"
						size="medium"
						name="rentAmount"
						fullWidth
						helperText={
							hasError('rentAmount') ? formState.errors.rentAmount[0] : null
						}
						error={hasError('rentAmount')}
						onChange={handleChange}
						type="number"
						value={formState.values.rentAmount || ''}
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="How have you been economically impacted?"
						label="How have you been economically impacted"
						variant="outlined"
						size="medium"
						name="impactReason"
						fullWidth
						helperText={
							hasError('impactReason') ? formState.errors.impactReason[0] : null
						}
						error={hasError('impactReason')}
						onChange={handleChange}
						type="text"
						value={formState.values.impactReason || ''}
						required
						multiline
						minRows={4}
					/>
				</Grid>
				<Grid item xs={12}>
					<i>
						<Typography variant="subtitle2">
							Fields that are marked with * sign are required.
						</Typography>
					</i>
				</Grid>
				<Grid item xs={12} alignItems="flex-end" className='action-btn-container'>
					<LoadingButton
						size="large"
						variant="contained"
						type="submit"
						color="primary"
						disabled={loading}
						disableElevation
						loading={loading}
					>
						Sign Up
					</LoadingButton>
				</Grid>
			</Grid>
		</form>
	);
};

SignUpComponent.propTypes = {
	loading: PropTypes.bool.isRequired,
	onSignUp: PropTypes.func.isRequired,
};

export default SignUpComponent;
