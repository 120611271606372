import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import './LoanCalculationFormStyle.scss';
import { formatCurrency } from "../../utils/formatter";

const schema = {
	state: {
		presence: { allowEmpty: false, message: 'is required' },
	},
	amount: {
		presence: { allowEmpty: false, message: 'is required' },
		numericality: {
			greaterThanOrEqualTo: 1,
			lessThanOrEqualTo: 5000
		}
	},
	term: {
		presence: { allowEmpty: false, message: 'is required' },
		numericality: {
			greaterThanOrEqualTo: 1,
			lessThanOrEqualTo: 12
		}
	},
};

const silder = {
	min: 1,
	max: 5000,
	step: 1,
};


const LoanCalculationForm = ({ states, loading, onFormSubmit, onBack }) => {
	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			amount: 1000,
			term: 12,
			selectedState: {}
		},
		touched: {},
		errors: {}
	});
	const theme = useTheme();

	const { interestAmount, total } = useMemo(() => {
		const principal = parseFloat(formState.values.amount);
		let interestAmount = 0, total = 0;
		if (principal > 0 && formState.values?.selectedState?.interest >= 0) {
			interestAmount = formState.values?.selectedState?.interest ? (principal * formState.values?.selectedState?.interest) / 100 : 0;
			total = principal + interestAmount;
		}
		return { interestAmount, total };
	}, [formState.values.amount, formState.values.selectedState]);
	
	const monthlyPayment = useMemo(() => {
		const term = parseInt(formState.values.term);
		let payment = 0;
		if (term > 0 && total >= 0) {
			payment = total / term;
		}
		return payment;
	}, [formState.values.term, total]);

	useEffect(() => {
		if (states?.length) {
			setFormState(formState => ({
				...formState,
				values: {
					...formState.values,
					state: 0,
					selectedState: states[0]
				},
				touched: {
					...formState.touched,
					state: true
				}
			}));
		}
	}, [states]);
		

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {}
		}));
	}, [formState.values]);

	/**
	 * Handle field change
	 * 
	 * @param {*} event 
	 */
	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
				event.target.type === 'checkbox'
					? event.target.checked
					: event.target.value
			},
			touched: {
				...formState.touched,
				[event.target.name]: true
			}
		}));
	};

	const handleSelectChange = (event) => {
		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:event.target.value,
				selectedState: states[event.target.value]
			},
			touched: {
				...formState.touched,
				[event.target.name]: true
			}
		}));
	};

	const handleSliderChange = (event, newValue) => {
		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				amount: newValue
			},
			touched: {
				...formState.touched,
				amount: true
			}
		}));
	};
	
	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	const handleSubmit = () => {
		if (formState.isValid) {
			onFormSubmit(formState.values);
		}
	};

	return (
		<>
			<Paper className="loan-form-paper" elevation={2}>
				{ loading ? <LinearProgress className="progress-bar" /> : null }
				<div className="loan-calculation-form">
					<div className="form-container">
						<form name="loan-form" className="loan-form" method="post" onSubmit={handleSubmit}>
							<FormControl fullWidth>
								<InputLabel id="state-select-label">State</InputLabel>
								<Select
									labelId="state-select-label"
									id="state-select"
									value={formState.values.state !== undefined ? formState.values.state : ''}
									label="State"
									name="state"
									onChange={handleSelectChange}
								>
									{states.map((state, index) => (
										<MenuItem key={state.id} value={index}>{state.state}</MenuItem>
									))}
								</Select>
							</FormControl>
							<div className="amount-field">
								<Typography className="field-label">Loan amount</Typography>
								<TextField
									placeholder="Loan Amount"
									variant="outlined"
									size="small"
									name="amount"
									helperText={
										hasError('amount') ? formState.errors.amount[0] : null
									}
									error={hasError('amount')}
									onChange={handleChange}
									type="number"
									value={formState.values.amount || ''}
									autoFocus={true}
									required
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
										inputProps: { min: 1, max: 5000 }
									}}
								/>
							</div>
							<Slider
								defaultValue={30}
								step={silder.step}
								min={silder.min}
								max={silder.max}
								value={formState.values.amount ? Number(formState.values.amount) : 0}
								onChange={handleSliderChange}
							/>
							<div className='slider-marks'>
								<Typography>${formatCurrency(silder.min)}</Typography>
								<Typography>${formatCurrency(silder.max)}</Typography>
							</div>

							<div className="amount-field">
								<Typography className="field-label">Term</Typography>
								<TextField
									placeholder="Term"
									variant="outlined"
									size="small"
									name="term"
									helperText={
										hasError('term') ? formState.errors.term[0] : null
									}
									error={hasError('term')}
									onChange={handleChange}
									type="number"
									value={formState.values.term || ''}
									autoFocus={true}
									required
									InputProps={{
										// startAdornment: <InputAdornment position="start">$</InputAdornment>,
										inputProps: { min: 1, max: 12 }
									}}
								/>
							</div>
							<div>
								<Typography>Repayment period: <b>Monthly</b> </Typography>
								<Typography>Approximate payment: <b>${formatCurrency(monthlyPayment)}</b> </Typography>
							</div>
							
						</form>
					</div>
					<div className="details-container" style={{ backgroundColor: theme.palette.primary.main }}>
						<Typography className='details-label'>Principal</Typography>
						<Typography className='details-value'>${formState.values.amount ? formatCurrency(parseFloat(formState.values.amount)) : 0.00}</Typography>
						<AddIcon className='add-icon' fontSize="medium" />
						<Typography className='details-label'>Interest ({formState.values?.selectedState?.interest}%)</Typography>
						<Typography className='details-value'>${formatCurrency(interestAmount)}</Typography>
						<Divider className='divider' />
						<Typography className='details-label'>Total</Typography>
						<Typography className='details-value total'>${formatCurrency(total)}</Typography>
					</div>
				</div>
			</Paper>


			<div className="footer-action">
				<Button
					color="inherit"
					disabled={true}
					onClick={onBack}
					sx={{ mr: 1 }}
					variant="outlined"
				>
					Back
				</Button>
				<Button type="button" variant="contained" onClick={handleSubmit} disabled={!formState.isValid}>
					Apply Now
				</Button>
			</div>
		</>
	);
};

LoanCalculationForm.propTypes = {
	states: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	onBack: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default LoanCalculationForm;