
const white = '#FFFFFF';
// const black = '#000000';

const palette = {
  primary: {
    contrastText: white,
    main: '#0F9AD8',
  },
  tertiary: {
    main: '#12235F',
    light: '#D6EDFE',
    dark: '#0A6B98',
    contrastText: '#FFFFFF',
  },

};

export default palette;