import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
import validate from 'validate.js';
import { State, City }  from 'country-state-city';
import { Helmet } from "react-helmet";

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Container from '@mui/material/Container';

import API from "../../services/axiosApi";
import './ProfileStyle.scss';
import { COMMON_ERR_MSG } from '../../config';
// import { updateCartCount, decrementCartCount, onUserProfileUpdate } from '../../redux/actions';
import ChangePasswordDialog from '../../components/ChangePasswordDialog';

const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 255,
		},
	},
	name: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 250,
		},
	},
	address: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 255,
		},
	},
	city: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128,
		},
	},
	state: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 128,
		},
	},
	zip: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 32,
		},
	},
	apartmentName: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			maximum: 255,
		},
	},
	rentAmount: {
		presence: { allowEmpty: false, message: 'is required' },
		/* length: {
			maximum: 120,
		}, */
	},
	impactReason: {
		presence: { allowEmpty: false, message: 'is required' },
	},
};

const COUNTRY_CODE_US = 'US';


const Profile = ({ history, onUserProfileUpdate }) => {
	const [loading, setLoading] = useState(false);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});
	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
  const [stateInputValue, setStateInputValue] = useState('');
  const [cityInputValue, setCityInputValue] = useState('');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);

  // const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));


	useEffect(() => {
		const fetchProfile = async () => {
			try {
				setLoading(true);
				const statesUS = State.getStatesOfCountry(COUNTRY_CODE_US);
				setStates(statesUS);

				const response = await API.get('profile');
				if (response.data?.success && response.data?.data) {
					const profileDetails = response.data.data.profile_details;
					
					setFormState(formState => ({
						...formState,
						values: {
							...formState.values,
							name					: profileDetails.name,
							email					: profileDetails.email,
							address				: profileDetails.address,
							city					: profileDetails.city,
							state					: profileDetails.state,
							zip						: profileDetails.zip,
							apartmentName	: profileDetails.apartmentName,
							unitNumber	: profileDetails.unitNumber,
							rentAmount		: profileDetails.rentAmount,
							impactReason	: profileDetails.impactReason,
						}
					}));
					const stateObj = statesUS.find(o => o.name === profileDetails.state);
					setSelectedState(stateObj);
					setStateInputValue(profileDetails.state);
					setCityInputValue(profileDetails.city);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchProfile : ", error);
				setLoading(false);
				const errorMsg = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : COMMON_ERR_MSG;
				handleSnackToogle(errorMsg);
			}
		};
		fetchProfile();
	}, []);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {},
		}));
	}, [formState.values]);

	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
					event.target.type === 'checkbox'
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...formState.touched,
				[event.target.name]: true,
			},
		}));
	};

	/**
	 * Handle profile update
	 * 
	 * @param {*} event 
	 */
	const handleSubmit = async (event) => {
		event.preventDefault();
		if (formState.isValid) {
			try {
				setLoading(true);

				const putData = {
					name: formState.values.name,
					address: formState.values.address,
					city: formState.values.city,
					state: formState.values.state,
					zip: formState.values.zip,
					apartmentName: formState.values.apartmentName,
					unitNumber: formState.values.unitNumber,
					rentAmount: formState.values?.rentAmount ? formState.values?.rentAmount.toString() : 0,
					impactReason: formState.values.impactReason,
				};
				const response = await API.put('profile', putData);
				handleSnackToogle(response.data.message);
				setLoading(false);
				/* onUserProfileUpdate({
					full_name: (putData.firstName + ' ' + putData.lastName).trim(),
					mobile: putData.mobile,
				}); */
			} catch (error) {
				console.log("ERROR in handleSubmit : ", error);
				setLoading(false);
				const errMsg = (error.response && error.response.data) ? error.response.data.message : COMMON_ERR_MSG;
				handleSnackToogle(errMsg);
			}
		}
	};

	/**
	 * Update account password
	 * 
	 * @param {*} data 
	 */
	const updatePassword = async (data) => {
		try {			
			if (data.password !== data.confirm) { handleSnackToogle("Password and confirm password doesn't match."); return; }
			setLoading(true);
			const response = await API.put('profile/update_password', { password: data.password });
			setLoading(false);
			handleSnackToogle(response.data.message);
		} catch (error) {
			console.log("ERROR in updatePassword : ", error);
			setLoading(false);
			const errorMsg = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : COMMON_ERR_MSG;
			handleSnackToogle(errorMsg);
		}
	}

	/**
	 * Show/hide change password dialog
	 * 
	 * @param {*} data 
	 */
	const handleDialogToggle = (data) => {
		setOpenDialog(openDialog => !openDialog);
		if (data && data.password) {
			updatePassword(data);
		}
	};

	/**
	 * Handle state autocomplete change
	 * 
	 * @param {Object} stateValue 
	 */
	const handleStateChange = (stateValue) => {
		// console.log("stateValue : ", stateValue);
		try {
			if (stateValue && stateValue.isoCode) {
				const citiesDetails = City.getCitiesOfState(COUNTRY_CODE_US, stateValue.isoCode);
				// console.log("citiesDetails : ", citiesDetails);
				setCities(citiesDetails);
			}
			setSelectedState(stateValue);
			setFormState(formState => ({
				...formState,
				values: { ...formState.values, state: (stateValue && stateValue.name) ? stateValue.name : '' },
				touched: { ...formState.touched, state: true },
			}));
		} catch (error) {
			console.log("ERROR in handleStateChange : ", error);			
		}
	};

	/**
	 * Handle city autocomplete change
	 * 
	 * @param {Object} cityValue 
	 */
	const handleCityChange = (cityValue) => {
		try {
			setSelectedCity(cityValue);
			setFormState(formState => ({
				...formState,
				values: { ...formState.values, city: cityValue },
				touched: { ...formState.touched, city: true },
			}));
		} catch (error) {
			console.log("ERROR in handleCityChange : ", error);			
		}
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	return (
		
		<div className="profile-root">
			<Helmet>
				<title>Rent Relief | Helping Americans With Rental Assistance</title>
				<meta name="description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
				<meta name="keywords" content="rent relief, rent relief for americans, help for rent relief, help for rent, refief from rent, how to get relief from rent, housing assistant, emergency rent relief, help to pay rent, covid rent refief,Rent Relief funds, rent assistance,apply for Rent Relief" />
				<meta property="og:title" content="Rent Relief | Helping Americans With Rental Assistance" />
				<meta property="og:description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
			</Helmet>
			<div className="pageTitle">
				{/* <IconButton size={isDesktop ? 'medium' : 'small'} aria-label="go-back" onClick={() => history.goBack()}>
					<ArrowBackIcon />
				</IconButton> */}
				<Typography variant="h5" className='text-bold'>Profile</Typography>
				{/* <div className={classes.spacer} /> */}
			</div>
			<Container className="form-conatiner">
				<form name="sigin-form" className="sigin-form" method="post" onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								placeholder="Name"
								label="Name"
								variant="outlined"
								size="medium"
								name="name"
								fullWidth
								helperText={
									hasError('name') ? formState.errors.name[0] : null
								}
								error={hasError('name')}
								onChange={handleChange}
								type="text"
								value={formState.values.name || ''}
								autoFocus={true}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								placeholder="E-mail"
								label="E-mail"
								variant="outlined"
								size="medium"
								name="email"
								fullWidth
								helperText={hasError('email') ? formState.errors.email[0] : null}
								error={hasError('email')}
								onChange={handleChange}
								type="email"
								value={formState.values.email || ''}
								required
								autoComplete="email"
								disabled
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								placeholder="Address"
								label="Address"
								variant="outlined"
								size="medium"
								name="address"
								fullWidth
								helperText={
									hasError('address') ? formState.errors.address[0] : null
								}
								error={hasError('address')}
								onChange={handleChange}
								type="text"
								value={formState.values.address || ''}
								required
							/>
						</Grid>
						<Grid item xs={4}>
							<Autocomplete
								freeSolo
								id="city-autocomplete"
								disableClearable
								options={cities.map((option) => option.name)}
								value={selectedCity}
								onChange={(event, newValue) => handleCityChange(newValue)}
								inputValue={cityInputValue}
								onInputChange={(event, newInputValue) => setCityInputValue(newInputValue)}
								fullWidth
								autoSelect
								renderInput={(params) => (
									<TextField {...params}
										label="City"
										variant="outlined"
										placeholder="City"
										name="city"
										size="medium"
										helperText={
											hasError('city') ? formState.errors.city[0] : null
										}
										error={hasError('city')}
										type="text"
										autoComplete='new-password'
										required
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4}>
							<Autocomplete
								value={selectedState}
								onChange={(event, newValue) => handleStateChange(newValue)}
								inputValue={stateInputValue}
								onInputChange={(event, newInputValue) => setStateInputValue(newInputValue)}
								id="state-autocomplete"
								fullWidth
								options={states}
								renderOption={(props, option) => <li {...props}>{option.name}</li>}
								getOptionLabel={option => option.name}
								renderInput={(params) => (
									<TextField {...params}
										label="State"
										variant="outlined"
										placeholder="State"
										name="state"
										size="medium"
										helperText={
											hasError('state') ? formState.errors.state[0] : null
										}
										error={hasError('state')}
										type="text"
										autoComplete='new-password'
										required
									/>
								)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								placeholder="Zip"
								label="Zip"
								variant="outlined"
								size="medium"
								name="zip"
								fullWidth
								helperText={
									hasError('zip') ? formState.errors.zip[0] : null
								}
								error={hasError('zip')}
								onChange={handleChange}
								type="text"
								value={formState.values.zip || ''}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								placeholder="Name of Apartment Complex"
								label="Name of Apartment Complex"
								variant="outlined"
								size="medium"
								name="apartmentName"
								fullWidth
								helperText={
									hasError('apartmentName') ? formState.errors.apartmentName[0] : null
								}
								error={hasError('apartmentName')}
								onChange={handleChange}
								type="text"
								value={formState.values.apartmentName || ''}
								required
								autoComplete='off'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								placeholder="Unit Number (Optional)"
								label="Unit Number"
								variant="outlined"
								size="medium"
								name="unitNumber"
								fullWidth
								error={hasError('unitNumber')}
								onChange={handleChange}
								type="text"
								value={formState.values.unitNumber || ''}
								autoComplete='off'
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								placeholder="Rent Amount (in USD)"
								label="Rent Amount"
								variant="outlined"
								size="medium"
								name="rentAmount"
								fullWidth
								helperText={
									hasError('rentAmount') ? formState.errors.rentAmount[0] : null
								}
								error={hasError('rentAmount')}
								onChange={handleChange}
								type="number"
								value={formState.values.rentAmount || ''}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								placeholder="How have you been impacted by COVID?"
								label="How have you been impacted by COVID"
								variant="outlined"
								size="medium"
								name="impactReason"
								fullWidth
								helperText={
									hasError('impactReason') ? formState.errors.impactReason[0] : null
								}
								error={hasError('impactReason')}
								onChange={handleChange}
								type="text"
								value={formState.values.impactReason || ''}
								required
								multiline
								minRows={4}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button color="primary" onClick={handleDialogToggle}>Change Password</Button>
						</Grid>
						<Grid item xs={12}>
							<i>
								<Typography variant="subtitle2">
									Fields that are marked with * sign are required.
								</Typography>
							</i>
						</Grid>
						<Grid item xs={12} alignItems="flex-end" className='action-btn-container'>
							<Button
								size="large"
								variant="contained"
								type="submit"
								color="primary"
								disabled={!formState.isValid || loading}
								disableElevation
							>
								Update
							</Button>
						</Grid>
					</Grid>
				</form>
			</Container>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={2000}
			/>

			<ChangePasswordDialog 
				open={openDialog}
				onClose={handleDialogToggle}
			/>
		</div>
	);
};


/* const mapDispatchToProps = (dispatch) => {
	return {
		updateCartCount: cartCount => dispatch(updateCartCount(cartCount)),
		decrementCartCount: increment => dispatch(decrementCartCount(increment)),
		onUserProfileUpdate: profile => dispatch(onUserProfileUpdate(profile)),
	};
}; */

export default Profile;