import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import "./ThemeButtonStyle.scss";


/**
 * Theme button component to display
 *
 * @param {Object} props
 */
const ThemeButton = ({ className, children, ...rest }) => {


	return (
		<Button
			variant="contained"
			size="large"
			color='tertiary'
			id="theme-button"
			className={className}
			{...rest}
		>
			{children}
		</Button>
	);
};

/* ThemeButton.defaultProps = {
	size: 'small',
};
 */
ThemeButton.propTypes = {
	/**
	 * External classes
	 */
	className: PropTypes.string,
	/**
	 * The classes of the font icon
	 */
	fontIconClass: PropTypes.string,
	/**
	 * Source set for the responsive images
	 */
	size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
	/**
	 * Color of the icon
	 */
	children: PropTypes.any,
};

export default ThemeButton;
