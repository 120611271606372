import React from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// import Carousel from 'react-material-ui-carousel';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme, useMediaQuery } from '@mui/material';

import './HomeStyle.scss';
import '../../App.css';
import AuthService from '../../services/authService';
import { ThemeButton } from '../../components/atoms';


import FamilyHomeImage from "../../assets/images/family-home.svg";
import HowItWorksImage from "../../assets/images/how-it-works.png";
import RentReliefFundsImage from "../../assets/images/rent-relief-funds.png";
import DepositImage from "../../assets/images/deposit.svg";
import EnergySavingImage from "../../assets/images/energy-saving-light.svg";
import HomeImage from "../../assets/images/home.svg";
import PaymentImage from "../../assets/images/payment.svg";
import ReceiptImage from "../../assets/images/receipt.svg";
import RentImage from "../../assets/images/rent.svg";
import RentAssistance from "../../assets/images/rent-assistance.png";
import HouseImage from "../../assets/images/house.svg";
import JobImage from "../../assets/images/job-loss.svg";
import keyImage from "../../assets/images/key.svg";
import TaxImage from "../../assets/images/tax.svg";
// import HomeFamilyImage from "../../assets/images/images-HomeFamily.png";
// import BannerTwoImage from "../../assets/images/banner-2.png";

/* const bannerItems = [
	{
		title: "Rent Relief insures families",
		titleLine2: "stay in their home.",
		subtitle: "Determine if you are eligible for Rent Relief!",
		image: BannerTwoImage,
	},
	{
		title: "Rent Relief insures families",
		titleLine2: "stay in their home.",
		subtitle: "Determine if you are eligible for Rent Relief.",
		image: HomeFamilyImage,
	},
	{
		title: "Rent Relief insures families",
		titleLine2: "stay in their home.",
		subtitle: "Determine if you are eligible for Rent Relief!",
		image: BannerTwoImage,
	},
	{
		title: "Rent Relief insures families",
		titleLine2: "stay in their home.",
		subtitle: "Determine if you are eligible for Rent Relief.",
		image: HomeFamilyImage,
	},
	{
		title: "Rent Relief insures families",
		titleLine2: "stay in their home.",
		subtitle: "Determine if you are eligible for Rent Relief!",
		image: BannerTwoImage,
	}
]; */ 

const Home = () => {
  const navigate = useNavigate();
	const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleLearnMore = () => {
		const path = AuthService.checkToken() ? '/loan' : '/sign-in';
		navigate(path);
	};


	return (
		<div className="home-screen">
			<Helmet>
				<title>Rent Relief | Helping Americans With Rental Assistance</title>
				<meta name="description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship." />
				<meta name="keywords" content="rent relief, rent relief for americans, help for rent relief, help for rent, refief from rent, how to get relief from rent, housing assistant, emergency rent relief, help to pay rent, covid rent refief, Rent Relief funds, rent assistance, apply for Rent Relief" />
				<meta property="og:title" content="Rent Relief | Helping Americans With Rental Assistance" />
				<meta property="og:description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship." />
			</Helmet>

			<div className="home-banner">
				<div className='banner-paper' >
					<div className="banner-paper-content">
						<Typography variant='h4' className="heading">Rent Relief insures families</Typography>
						<Typography variant='h4' className="heading">stay in their home.</Typography>
						<Typography className="subtitle">Determine if you are eligible for Rent Relief</Typography>
						<ThemeButton size={isSmScreen ? 'medium' : 'large'} className="accounts-button" onClick={handleLearnMore} disableElevation>
							Get Rent Relief
						</ThemeButton>
					</div>
				</div>
				{/* <Carousel>
					{bannerItems.map((item, index) => <BannerItem key={index} item={item} handleLearnMore={handleLearnMore} /> )}
				</Carousel> */}
			</div>
			{/* <div className="d-flex home-family">
				<div className="row align-self-center accounts-title">
					<div>
						<Typography variant='h4' className="heading">
							Rent Relief isures families <br/>
							stay in their home.
						</Typography>
						<Typography className="accounts-body">Determine if you are eligible for Rent Relief.</Typography>
						<ThemeButton className="accounts-button" onClick={handleLearnMore}>
							Get Rent Relief
						</ThemeButton>
					</div>
				</div>
			</div> */}

			{/* KEEPING FAMILIES START */}
			<div className="keeping-family-homes d-flex h-100 my-5">
				<Grid container spacing={4} className="keeping-family-content">
					<Grid item mobile={12} sm={6}>
						<img alt="" className="family-image mt-3" src={FamilyHomeImage} />
					</Grid>
					{/* col-6 align-self-center */}
					<Grid item mobile={12} sm={6} className="keeping-family-text">
						<Typography color="tertiary" variant='h4' className="section-title">
							Keeping families in <br /> their Homes
						</Typography>
						<Typography className="keeping-family-text">
							Rent Relief is helping Americans with rental assistance for anyone
							experiencing financial hardship. Rent Relief can provide rental 
							assistance no matter where you live, in a residential home or a 
							multi-family complex, and is available to anyone that has been 
							adversely affected by COVID-19 or experiencing any financial
							hardship.
						</Typography>
						<Typography className="keeping-family-subtitle">
							Now that the eviction moratorium has lifted, millions of residents are facing eviction and Rent Relief can help to insure you stay in your home. 
						</Typography>
						<ThemeButton onClick={handleLearnMore}>
							Get Rent Relief Fund
						</ThemeButton>
					</Grid>
				</Grid>
			</div>
			{/* KEEPING FAMILIES END */}

			{/* RENT RELIEF FUNDS START */}
			<div className="rent-relief-funds d-flex h-100 color-lightblue">
				<Typography variant='h4' className="section-title" align='center'>
					Rent Relief funds can <br /> be applied to
				</Typography>
				<Grid container spacing={4} className="relief-funds-content" justifyContent="center" alignItems="center">
					{/* col-6 align-self-center */}
					<Grid item mobile={12} sm={6} className="relief-funds-text">
						<Grid container spacing={{ xs: 4, sm: 8, md: 10 }}>
							<IconBoxComponent imageSrc={RentImage} title="Current Rent" />
							<IconBoxComponent imageSrc={PaymentImage} title="Past Due Rent" />
							<IconBoxComponent imageSrc={HomeImage} title="Utilities" />
							<IconBoxComponent imageSrc={DepositImage} title="Security Deposit" />
							<IconBoxComponent imageSrc={EnergySavingImage} title="Home Energy" />
							<IconBoxComponent imageSrc={ReceiptImage} title="Eligible Fees" />
						</Grid>
					</Grid>

					<Grid item mobile={12} sm={6} className="rent-image-container">
						<img alt="" className="family-image mt-3" src={RentReliefFundsImage} />
					</Grid>
				</Grid>
			</div>
			{/* RENT RELIEF FUNDS END */}

			{/* RENT ASSISTANCE START */}
			<div className="rent-assitance-homes d-flex h-100">
				<Typography variant='h4' className="section-title" align='center'>
					Who is eligible for rent <br/> assistance?
				</Typography>
				<Grid container spacing={4} className="rent-assitance-content">
					<Grid item mobile={12} sm={6}>
						<img alt="" className="family-image mt-3" src={RentAssistance} />
						<ThemeButton onClick={handleLearnMore}>
							Get Rent Relief Fund
						</ThemeButton>
					</Grid>
					{/* col-6 align-self-center */}
					<Grid item mobile={12} sm={6}>
						<Grid container spacing={{ xs: 4, sm: 4, md: 6, mobileSmall: 2 }}>
							<TextBoxComponent icon={HouseImage} title="Individuals who reside in the household and are on the lease." />
							<TextBoxComponent icon={JobImage} title="Individuals experiencing instability due to Covid-19 or other financial hardship (lost job, past due rent, utilities, etc)" />
							<TextBoxComponent icon={keyImage} title="Residents that have received an eviction notice. " />
							<TextBoxComponent icon={TaxImage} title="Individuals who have qualified for unemployment, experienced a reduction in income, or experienced hardship due to Covid-19." />							
						</Grid>
					</Grid>
				</Grid>
			</div>
			{/* RENT ASSISTANCE END */}

			{/* HOW IT WORKS START */}
			<div className="row how-it-works color-lightblue d-flex h-100">
				<div className="how-it-works-title">
					<Typography variant='h4' className="section-title" align='center'>
						How it Works
					</Typography>
					{/* <Typography align='center'>
						Few simple steps and you'll get the funds.
					</Typography> */}
				</div>
				{/* offset-1 col-10 align-self-center */}
				<div className="how-it-works-content align-self-center mb-5 ">
					<img src={HowItWorksImage} alt="How It Works" />
				</div>
			</div>
			{/* HOW IT WORKS END */}

			{!AuthService.checkToken() && (
				<div className="sign-up-link">
					<Typography variant='h4' className="sign-up-text" align='center'>
						Create your account and apply for Rent Relief
					</Typography>
					<ThemeButton color="primary" onClick={handleLearnMore}>
						Get Rent Relief Fund
					</ThemeButton>
				</div>
			)}
		</div>
	);
};

/* const BannerItem = ({ item, handleLearnMore }) => {

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<div className='banner-paper' style={{ backgroundImage: `url(${item.image})` }} >
				<div className="banner-paper-content">
					<Typography variant='h4' className="heading">{item.title}</Typography>
					<Typography variant='h4' className="heading">{item.titleLine2}</Typography>
					<Typography className="subtitle">{item.subtitle}</Typography>
					<ThemeButton size={isSmScreen ? 'medium' : 'large'} className="accounts-button" onClick={handleLearnMore} disableElevation>
						Get Rent Relief
					</ThemeButton>
				</div>
		</div>
	);
}; */


const IconBoxComponent = ({ imageSrc, title }) => {
	return (
		<Grid item mobile={12} sm={6} laptop={4} mobileLarge={4} mobileSmall={6}>
			<div className="icon-box-content">
				<Paper className="icon-image-paper" elevation={0}>
					<img alt={title} src={imageSrc} />
				</Paper>
				<Typography className="icon-box-text">{title}</Typography>
				{/* <div className='icon-box-text'>
					{subtitle && <Typography variant='h5' className="text-bold">{subtitle}</Typography>}
				</div> */}
			</div>
		</Grid>
	);
};

const TextBoxComponent = ({ icon, title }) => {
	return (
		<Grid item xs={12} sm={12} laptop={6} mobileLarge={6}>
			<div className="text-box-content">
				<div className="text-paper" elevation={0}>
					<img alt={title} src={icon} />
					<Typography className="icon-box-text">{title}</Typography>
				</div>
				{/* <div className='icon-box-text'>
					{subtitle && <Typography variant='h5' className="text-bold">{subtitle}</Typography>}
				</div> */}
			</div>
		</Grid>
	);
};


export default Home;
