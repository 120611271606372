import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import './AuthStyle.scss';
import '../../App.css';
// import PaymentImage from "../../assets/images/image-payment.png";
// import EvictionImage from "../../assets/images/eviction.png";
import API from "../../services/axiosApi";
import { COMMON_ERR_MSG } from "../../config";
import { SignInForm, SignUpForm } from "./components";

const Auth = () => {
  const navigate = useNavigate();
	const [activeAuth, setActiveAuth] = useState('signin');
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [snack, setSnack] = useState({ open: false, message: '' });

	const handleAuthTabChange = (tabValue) => {
		setActiveAuth(tabValue);
		setErrorMessage('');
	};

	/**
	 * Hanlde sign-in
	 * 
	 * @param {Object} formData 
	 */
	const handleSignIn = async (formData) => {
		setLoading(false);
		setErrorMessage('');
		try {
			setLoading(true);
			const postData = { ...formData };
			delete postData.showPassword;
			const response = await API.post('signin', postData);
			setLoading(false);
			if (response.data.success) {
				// handleSnackToogle(response.data.message);
				navigate('/applications');
				window.scrollTo(0, 0);
				// history.push('/');
			} else {
				const errorMsg = response.data.message || 'Invalid Credentials';
				setErrorMessage(errorMsg);
			}
		} catch (error) {
			console.log("ERROR in handleSignUp : ", error);
			setLoading(false);
			const errMsg = (error.response && error.response.data) ? error.response.data.message : COMMON_ERR_MSG;
			setErrorMessage(errMsg);
		}
	};

	/**
	 * Handle sign-up
	 * 
	 * @param {Object} formData 
	 */
	const handleSignUp = async (formData) => {
		setLoading(false);
		setErrorMessage('');
		try {
			setLoading(true);
			const postData = { ...formData };
			delete postData.showPassword;
			const response = await API.post('signup', postData);
			setLoading(false);
			if (response.data.success) {
				handleSnackToogle(response.data.message);
				setActiveAuth('signin');
				window.scrollTo(0, 0);
				// navigate('/');
				// history.push('/');
			} else {
				const errorMsg = response.data.message || 'Invalid Credentials';
				setErrorMessage(errorMsg);
			}
		} catch (error) {
			console.log("ERROR in handleSignUp : ", error);
			setLoading(false);
			const errMsg = (error.response && error.response.data) ? error.response.data.message : COMMON_ERR_MSG;
			setErrorMessage(errMsg);
		}
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	return (
		<div className="signin-screen">

			<div className="auth-form">
				<div className="form-header">
					<Typography variant='h5' className="text-bold" align='center'>
						Fill in the form for Rent Relief
					</Typography>
					<div className="form-header-btns">
						<Button variant="contained" size='large' className={`auth-button auth-button-left ${activeAuth === 'signup' ? 'auth-inactive' : ''}`} onClick={() => handleAuthTabChange('signin')} disableElevation>Sign In</Button>
						<Button variant="contained" size='large' className={`auth-button auth-button-right ${activeAuth === 'signin' ? 'auth-inactive' : ''}`} onClick={() => handleAuthTabChange('signup')} disableElevation>Sign Up</Button>
					</div>
				</div>
				{errorMessage &&
						<Alert className="error-alert" severity="error">{ errorMessage }</Alert>
					}
				{activeAuth === 'signin' ? <SignInForm loading={loading} onLogin={handleSignIn} /> : <SignUpForm onSignUp={handleSignUp} loading={loading} /> }
			</div>
			{/* <div className="d-flex h-100 sign-accounts-key">
				<div className="row accounts-title offset-1">
					<Typography variant='h1' className="text-bold">
						Create a New Account<br/>
						with Tenant Application
					</Typography>
					<Typography className="accounts-body">Determine if you are eligible for Rent Relief.</Typography>
				</div>
			</div>
			<div className="h-100 my-5 create-account">
				<div>
					<Typography variant='h4' align='center' className="text-bold">Create a new account with</Typography>
					<Typography variant='h4' align='center' className="text-bold">tenant application to apply for rent</Typography>
					<Typography variant='h4' align='center' className="text-bold">relief through our easy application process</Typography>
				</div>
				<div className="create-account-content">
					<div className="create-account-image align-self-center">
						<img className="payment-image" alt='payment' src={PaymentImage} />
					</div>
					<div className="create-account-text align-self-center">
						<Typography variant='h3' className="text-bold">
							Payments
						</Typography>
						<Typography>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</Typography>
					</div>
				</div>
			</div>
			<div className="color-lightblue">
				<div className="create-account-content">
					<div className="create-account-text align-self-center">
						<Typography variant='h3' className="text-bold">
							Eviction Diversion
						</Typography>
						<Typography>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
						</Typography>
					</div>
					<div className="create-account-image align-self-center">
						<img className="payment-image" alt='payment' src={EvictionImage} />
					</div>
				</div>
			</div> */}
			{/* <div className="color-lightblue">
				<div className="row d-flex h-100 py-5">
					<div className="offset-sm-0 col-sm-6 offset-md-1 col-md-4 align-self-center">
						<div>
							<div className="col-12">
								<Typography variant='h4' align='center' className="text-bold">
									Eviction Diversion
								</Typography>
							</div>
							<div className="col-12">
								<Typography>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</Typography>
							</div>
						</div>
					</div>
					<div className="offset-sm-0 col-sm-6 offset-md-2 col-md-4 align-self-center">
							<img className="payment-image" src={EvictionImage} alt="Eviction" />
					</div>
				</div>
			</div> */}

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
		</div>
	);
};


export default Auth;
