import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Image } from '../../atoms';
// import LogoSVG from '../../../assets/images/logo_01.svg';
import LogoSVG from '../../../assets/images/logo-text.png';
import './FooterStyle.scss';

const Footer = ({ className, ...rest }) => {
	return (
		<footer className="border-top footer-component text-muted">
      <Container maxWidth="lg" className="footer-container">
				<a href="/" title="Rent Relief">
					<Image className="footer-logo m-2" src={LogoSVG} alt="Rent Relief" lazy={false} />
				</a>
				
				<div className='footer-links'>
					<Link className='pr-4' target="_blank" to="terms">Terms & Conditions</Link>
					<Link className='pr-4' target="_blank" to="privacy-policy">Privacy Policy</Link>
					<Typography variant='h6' className="rights" align='center'>
						All Copyrights Reserved 2022.
					</Typography>
				</div>
      </Container>
    </footer>
	);
};

Footer.propTypes = {
	className: PropTypes.string,
};

export default Footer;
