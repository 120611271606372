// import palette from './palette';

const typography = {
	fontFamily: `"Roboto", "Poppins"`,
	// subtitle2: {
		// color: palette.text.secondary,
		// fontWeight: 400,
		// fontSize: '16px',
		// lineHeight: 1.4,
	// },
	
};

export default typography;
