import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@mui/material/Drawer';

import { SidebarNav } from './components';
import './SidebarStyle.scss';

const Sidebar = ({ pages, open, variant, onClose, className, products, ...rest }) => {

  return (
    <Drawer
      anchor="left"
      classes={{ paper: 'drawer' }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={`root ${className}`}>
        <SidebarNav className="nav" pages={pages} onClose={onClose} products={products} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
	// products: PropTypes.array.isRequired,
};

export default Sidebar;
