import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';


import API from "../../services/axiosApi";
import './ApplicationStyle.scss';
import { COMMON_ERR_MSG } from '../../config';
import { EnhancedTablePaginationActions, EnhancedTableHead } from '../../components/TableComponent';
import { formatDate, formatCurrency, formatUnderscore } from '../../utils/formatter';
// import { updateCartCount, decrementCartCount, onUserProfileUpdate } from '../../redux/actions';

const headCells = [
	{ key: 'loanNumber', numeric: false, disablePadding: false, label: 'Loan Number', sortable: true },
	{ key: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true },
	{ key: 'city', numeric: false, disablePadding: false, label: 'City', sortable: true },
	{ key: 'state', numeric: false, disablePadding: false, label: 'State', sortable: true },
	{ key: 'zip', numeric: false, disablePadding: false, label: 'Zip', sortable: true },
	{ key: 'loanTotal', numeric: false, disablePadding: false, label: 'Loan amount', sortable: true },
	{ key: 'order_status', numeric: false, disablePadding: false, label: 'Status', sortable: false },
	{ key: 'createdAt', numeric: false, disablePadding: false, label: 'Submitted At', sortable: true },
];

const Applications = () => {
  const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [records, setRecords] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('createdAt');
	const [pageSize, setPageSize] = useState(10);
	const [page, setPage] = useState(0);
	const [snack, setSnack] = useState({ open: false, message: '' });

  // const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));


	useEffect(() => {
		const fetchApplications = async () => {
			try {
				setLoading(true);
				let postData = {
					order,
					orderBy,
					pageOffset: page * pageSize,
					pageSize,
					// searchText: debouncedSearch,
				};
				const response = await API.post('applications', postData);
				if (response.data.success && response.data.data) {
					setRecords(response.data.data.rows);
					setTotalRecords(response.data.data.count);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchApplications : ", error);
				setLoading(false);
				const errorMsg = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : COMMON_ERR_MSG;
				handleSnackToogle(errorMsg);
			}
		};
		fetchApplications();
	}, [page, pageSize, order, orderBy]);

	/**
	 * Handle sorting change
	 * 
	 * @param {*} event 
	 * @param {*} property 
	 */
	 const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	/**
	 * Handle page change and refeacth data
	 * 
	 * @param {*} event 
	 * @param {*} newPage 
	 */
	 const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	/**
	 * On change page size
	 * 
	 * @param {*} event 
	 */
	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
		setPage(0);
	};

	/**
	 * Proceed to checkout screen
	 */
	const openDetailPage = (index) => {
		if (records[index]) {
			navigate('/applications/' + records[index].id);
		}
	};

	const getChipColor = (appStatus) => {
		let color = '#f8c528';
		switch (appStatus) {
			case 'approved'		: color = '#4CAF4F';	break;
			case 'rejected'		: color = '#D43C30';	break;
			default						: color = '#F8C528';	break;
		}
		return color;
	};

	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	return (
		<div className="applications-root">
			<Helmet>
				<title>Rent Relief | Helping Americans With Rental Assistance</title>
				<meta name="description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
				<meta name="keywords" content="rent relief, rent relief for americans, help for rent relief, help for rent, refief from rent, how to get relief from rent, housing assistant, emergency rent relief, help to pay rent, covid rent refief,Rent Relief funds, rent assistance,apply for Rent Relief" />
				<meta property="og:title" content="Rent Relief | Helping Americans With Rental Assistance" />
				<meta property="og:description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
			</Helmet>
			<div className="pageTitle">
				<Typography variant="h5" className='text-bold'>My Applications</Typography>
			</div>
			<Container className="content">
				{totalRecords ? (
					<Paper className="paper">
						{ loading ? <LinearProgress className="progress-bar" /> : null }
						<TableContainer>
							<Table className="table" size='medium'>
								<EnhancedTableHead
									numSelected={0}
									order={order}
									orderBy={orderBy}
									onSelectAll={() => {}}
									onRequestSort={handleRequestSort}
									rowCount={records.length}
									headCells={headCells}
									haveMultiSelect={false}
								/>
								<TableBody>
								{ records.length === 0 ? (
									<TableRow>
										<TableCell colSpan={headCells.length}>No records found</TableCell>
									</TableRow> ): null }
								{ records.map((row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;
									return (
										<TableRow
											hover
											key={index}
											onClick={() => openDetailPage(index)}
											className="row-hover"
										>
											<TableCell id={labelId} scope="row">
												{row.loanNumber}
											</TableCell>
											<TableCell>{row.user?.email || '-'}</TableCell>
											<TableCell>{row.user?.city || '-'}</TableCell>
											<TableCell>{row.user?.state || '-'}</TableCell>
											<TableCell>{row.user?.zip || '-'}</TableCell>
											<TableCell>${formatCurrency(row.loanTotal)}</TableCell>
											<TableCell>
												<Chip className="status-chip" style={{ backgroundColor: getChipColor(row.status) }} label={formatUnderscore(row.status)} />
											</TableCell>
											<TableCell>{formatDate(row.createdAt)}</TableCell>
											{/* <TableCell align="right">{row.label}</TableCell> */}
										</TableRow>
									);
								}) }
								{/* emptyRows > 0 && (
									<TableRow style={{ height:  53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								) */}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 100]}
							component="div"
							count={totalRecords}
							rowsPerPage={pageSize}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={EnhancedTablePaginationActions}
							classes={{
								toolbar: 'pagination-toolbar'
							}}
						/>
					</Paper>
				) : (
					<div className="empty-section">
						<Typography variant='h6' className="empty-text">You haven't applied for any rent relief yet!</Typography>
						<Button
							size="large"
							variant="contained"
							color="primary"
							onClick={() => navigate('/loan')}
						>
							Get Rent Relief
						</Button>
					</div>
				)}
			</Container>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={2000}
			/>
		</div>
	);
};


/* const mapDispatchToProps = (dispatch) => {
	return {
		updateCartCount: cartCount => dispatch(updateCartCount(cartCount)),
		decrementCartCount: increment => dispatch(decrementCartCount(increment)),
		onUserProfileUpdate: profile => dispatch(onUserProfileUpdate(profile)),
	};
}; */

export default Applications;