import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import typography from './typography';
import breakpoints from './breakpoints';
import palette from './palette';
// import overrides from './overrides';

const theme = responsiveFontSizes(
	createTheme({
		typography,
		breakpoints,
		palette,
		/* layout: {
			contentWidth: 1140,
		},
		zIndex: {
			appBar: 1200,
			drawer: 1100,
		},
		overrides: {
			...overrides,
			MuiButton: {
				containedSecondary: {
					color: 'white',
				},
			},
		}, */
	}),
);

export default theme;