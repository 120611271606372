import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';

// import { formatCurrency } from '../utils/formatter';
import './UploadingProgressStyle.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});


const UploadingProgress = ({ open, progressPercentage }) => {
	return (
		<Dialog
			// onClose={() => onClose()}
			onClose={() => {}}
			open={open}
			disableEscapeKeyDown={true}
			TransitionComponent={Transition}
			classes={{ paper: 'upload-dialog-fullwidth' }}
		>
			<DialogTitle id="upload-progress-dialog-title">Uploading Application</DialogTitle>
			<DialogContent className="upload-dialog-content">
				{progressPercentage < 100 ? (
					<>
						<CircularProgress
							variant="determinate"
							value={progressPercentage}
							size={80}
						/>
						<Typography variant="h5" className="percentage">
							{Math.ceil(progressPercentage)} %
						</Typography>
					</>
				) : 'Processing...'}
				
			</DialogContent>
		</Dialog>
	);
};

UploadingProgress.propTypes = {
	open: PropTypes.bool.isRequired,
	// onClose: PropTypes.func.isRequired,
	progressPercentage: PropTypes.number.isRequired,
};

export default UploadingProgress;