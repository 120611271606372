import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from "react-router-dom";

import AuthService from '../../services/authService';


const UnauthenticatedRoute = ({ accessRole, children }) => {

	const location = useLocation();
	if (AuthService.checkRoute(accessRole)) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/" state={{ from: location }} replace />;
	}
  return children;
};

UnauthenticatedRoute.propTypes = {
	accessRole: PropTypes.string,
};

export default UnauthenticatedRoute;