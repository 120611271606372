import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


import './LoanStyle.scss';
import '../../App.css';
import API from "../../services/axiosApi";
import UploadingProgressDialog from "../../components/UploadingProgressDialog";
import LoanCalculationForm from "../../components/LoanCalculationForm";
import UploadDocumentForm from "../../components/UploadDocumentForm";
import { COMMON_ERR_MSG } from "../../config";


const steps = [
	{ key: '', label: 'Select loan terms' },
	{ key: '', label: 'Upload Documents'}
];

const Loan = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [progressPercentage, setProgressPercentage] = useState(0);
	const [snack, setSnack] = useState({ open: false, message: '' });
	const [activeStep, setActiveStep] = useState(0);
	const [loanFormData, setLoanFormData] = useState({});
	const [states, setStates] = useState([]);

	useEffect(() => {
		const fetchStates = async () => {
			try {
				setLoading(true);
				const response = await API.get('states');
				if (response.data.success && response?.data?.data?.length) {
					setStates(response.data.data);
				}
				setLoading(false);
			} catch (error) {
				console.log("ERROR in fetchStates : ", error);
				setLoading(false);
			}
		};
		fetchStates();
	}, [setLoading]);


	/* React.useEffect(() => {
		console.log("uploadedFiles : ", uploadedFiles);
	}, [uploadedFiles]); */


	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};


	/**
	 * Handle documents upload form submit
	 * 
	 * @param {Object} formValues 
	 */
	const handleDocumentSubmit = async (uploadedFiles) => {
		try {
			setLoading(false);
			setProcessing(false);
			setLoading(true);
			const formData = new FormData();
			formData.append('lease', uploadedFiles.lease, uploadedFiles.lease.name);
			if (uploadedFiles.impactLetter) { formData.append('impactLetter', uploadedFiles.impactLetter, uploadedFiles.impactLetter.name); }
			if (uploadedFiles.rentInvoice) { formData.append('rentInvoice', uploadedFiles.rentInvoice, uploadedFiles.rentInvoice.name); }
			if (uploadedFiles.securityDeposit) { formData.append('securityDeposit', uploadedFiles.securityDeposit, uploadedFiles.securityDeposit.name); }
			if (uploadedFiles.utilityBills) { formData.append('utilityBills', uploadedFiles.utilityBills, uploadedFiles.utilityBills.name); }
			if (uploadedFiles.internetInvoiceStatement) { formData.append('internetInvoiceStatement', uploadedFiles.internetInvoiceStatement, uploadedFiles.internetInvoiceStatement.name); }
			if (uploadedFiles.dueRentStatement) { formData.append('dueRentStatement', uploadedFiles.dueRentStatement, uploadedFiles.dueRentStatement.name); }
			if (uploadedFiles.additionalInformation) { formData.append('additionalInformation', uploadedFiles.additionalInformation, uploadedFiles.additionalInformation.name); }
			formData.append('data', JSON.stringify(loanFormData));

			setProcessing(true);
			const response = await API.post('application', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
				onUploadProgress: progressEvent => {
					const progressPercentage = progressEvent.loaded / progressEvent.total * 100;
					console.log("progressPercentage : ", progressPercentage);
					setProgressPercentage(progressPercentage);
				}
			});
			setProcessing(false);
			setLoading(false);
			if (response.data.success) {
				handleSnackToogle(response.data.message);
				navigate('/applications');
			}
		} catch (error) {
			setProcessing(false);
			setLoading(false);
			const errMsg = error?.response?.data?.message || COMMON_ERR_MSG;
			handleSnackToogle(errMsg);
		}		
	};

	/**
	 * Handle load form submit
	 * 
	 * @param {Object} formValues 
	 */
	const handleLoanSubmit = async (formValues) => {
		setLoanFormData(loanFormData => ({
			...loanFormData,
			...formValues
		}));
		handleNext();
	};


	const handleSnackToogle = (message) => {
		setSnack(snack => ({ open: !snack.open, message: message || '' }));
	};

	const renderFormSteps = () => {
		switch (activeStep) {
			case 0: return (
				<LoanCalculationForm					
					states={states}
					loading={loading}
					onBack={handleBack}
					onFormSubmit={handleLoanSubmit}
				/>
			);
			case 1: return (
				<UploadDocumentForm
					setLoading={setLoading}
					loading={loading}
					onBack={handleBack}
					onFormSubmit={handleDocumentSubmit}
					onSnackToogle={handleSnackToogle}
				/>
			);
		
			default: return (<>		
				<Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
					<Button
						color="inherit"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						Back
					</Button>
					<Box sx={{ flex: '1 1 auto' }} />
					<Button onClick={handleNext}>
						{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
					</Button>
				</Box>
			</>);
		}
	};

	return (
		<div className="loan-screen">
			<Helmet>
				<title>Rent Relief | Helping Americans With Rental Assistance</title>
				<meta name="description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
				<meta name="keywords" content="rent relief, rent relief for americans, help for rent relief, help for rent, refief from rent, how to get relief from rent, housing assistant, emergency rent relief, help to pay rent, covid rent refief,Rent Relief funds, rent assistance,apply for Rent Relief" />
				<meta property="og:title" content="Rent Relief | Helping Americans With Rental Assistance" />
				<meta property="og:description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
			</Helmet>
			<Typography variant='h5' className="title text-bold" align='center'>
				Welcome to the Rent Relief Loan Program!
			</Typography>
			<Container className="content">
				<Paper className="paper-container" elevation={2}>
					<Stepper activeStep={activeStep} orientation="horizontal">
						{steps.map((step, index) => (
							<Step key={index}>
								<StepLabel>{step.label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Paper>

				{renderFormSteps()}
				

				{/* <div className="footer-action">
					<Button
						color="inherit"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
						variant="outlined"
					>
						Back
					</Button>
					<Button type="button" variant="contained" onClick={handleNext} disabled={!isFormValid}>
						{activeStep ? 'Upload' : 'Apply Now'}
					</Button>
				</div> */}

			</Container>

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={snack.open}
				onClose={() => handleSnackToogle()}
				message={snack.message}
				autoHideDuration={3000}
			/>
			<UploadingProgressDialog
				open={processing}
				progressPercentage={progressPercentage}
			/>
		</div>
	);
};


export default Loan;
