import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import "./LearnMoreLinkStyle.scss";

/**
 * Component to display the "Learn More" link
 *
 * @param {Object} props
 */
const LearnMoreLink = props => {
	const {
		color,
		component,
		variant,
		title,
		href,
		className,
		iconProps,
		typographyProps,
		...rest
	} = props;

	const children = (
		<>
			<Typography
				component="span"
				className={`learn-more-link__typography title`}
				variant={variant}
				color={color || 'primary'}
				{...typographyProps}
			>
				{title}
			</Typography>
			<IconButton
				className={`learn-more-link__icon-button icon`}
				color={color || 'primary'}
				{...iconProps}
			>
				<ArrowRightAltIcon className="learn-more-link__arrow" />
			</IconButton>
		</>
	);

	return (
		<a
			href={href}
			className={`learn-more-link learn-root ${className}`}
			{...rest}
		>
			{children}
		</a>
	);
};

LearnMoreLink.defaultProps = {
	variant: 'subtitle1',
	href: '#',
	typographyProps: {},
	iconProps: {},
	component: 'a',
};

LearnMoreLink.propTypes = {
	/**
	 * External classes
	 */
	className: PropTypes.string,
	/**
	 * The component to load as a main DOM
	 */
	component: PropTypes.oneOf(['Link', 'a']),
	/**
	 * Title of the link
	 */
	title: PropTypes.string.isRequired,
	/**
	 * Variant of the link
	 */
	variant: PropTypes.oneOf(['h6', 'subtitle1', 'subtitle2', 'body1', 'body2']),
	/**
	 * Href of the link
	 */
	href: PropTypes.string,
	/**
	 * Color of the link
	 */
	color: PropTypes.string,
	/**
	 * Additional properties to pass to the Icon component
	 */
	iconProps: PropTypes.object,
	/**
	 * Additional properties to pass to the Typography component
	 */
	typographyProps: PropTypes.object,
};

export default LearnMoreLink;
