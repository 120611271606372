import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { Link, useNavigate } from 'react-router-dom';
// import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
// import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';

import { Image, ThemeButton } from '../../atoms';
import AuthService from '../../../services/authService';
// import API from '../../../services/axiosApi';
// import LogoSVG from '../../../assets/images/logo_01.svg';
import LogoSVG from '../../../assets/images/logo-text.png';
// import { SERVER_PATH, PROFILE_IMAGE_PATH } from '../../../config';
// import { updateCartCount, onUserProfileUpdate } from 'redux/actions';
import './HeaderStyle.scss';


/* const useStyles = makeStyles(theme => ({
	root: {},
	flexGrow: {
		flexGrow: 1,
	},
	navigationContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	toolbar: {
		maxWidth: theme.layout.contentWidth,
		width: '100%',
		margin: '0 auto',
		padding: theme.spacing(0, 2),
	},
	listItem: {
		cursor: 'pointer',
		paddingTop: 0,
		paddingBottom: 0,
	},
	listItemText: {
		flex: '0 0 auto',
		whiteSpace: 'nowrap',
		textDecoration: 'none',
	},
	listItemButton: {
		whiteSpace: 'nowrap',
	},
	iconButton: {
		padding: 0,
		'&:hover': {
			background: 'transparent',
		},
	},
	logoContainer: {
		width: 100,
		height: 28,
		[theme.breakpoints.up('md')]: {
			width: 120,
			height: 32,
		},
	},
	logoImage: {
		width: '100%',
		height: '100%',
	},
	popperStyle: {
		zIndex: 1,
	},
	avatar: {
		cursor: 'pointer',
	}
})); */

const Header = ({ onSidebarOpen, profileData, onUserProfileUpdate, ...rest }) => {

  const navigate = useNavigate();
	const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const decoded = AuthService.decodeAccessToken();
	const userDataLocal = (localStorage.getItem('user_data') !== null && localStorage.getItem('user_data') !== undefined) ? JSON.parse(localStorage.getItem('user_data')) : decoded;
	const [userData, setUserData] = useState(userDataLocal);
	// console.log(decoded);
	// const imageAssetPath = 'SERVER_PATH + PROFILE_IMAGE_PATH';
	// const [imageSource] = useState(imageAssetPath + userData.profile_image);
	// const [imageSource, setImageSource] = useState(imageAssetPath + userData.profile_image);

	useEffect(() => {
		setUserData(userData => ({ ...userData, ...profileData }));
	}, [profileData]);


	useEffect(() => {
		const isLoggedIn = AuthService.getAuth();
		if (isLoggedIn) {

		}
	}, [onUserProfileUpdate]);

	const logoutUser = (event) => {
		setAnchorEl(null);
		// AuthService.logout();
		localStorage.clear();
		navigate('/');
	};

	const handleRentRelief = () => {
		const path = AuthService.checkToken() ? '/loan' : '/sign-in';
		navigate(path);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Toolbar id='header-component' {...rest}>
			<div className="logoContainer">
				<a href="/" title="thefront">
					{/* <Typography className="title" variant="h4">Posteresque</Typography> */}
					<Image
						className="logoImage"
						src={LogoSVG}
						alt="Rent Relief"
						lazy={false}
					/>
				</a>
			</div>
			<div className="flexGrow" />
			{matches ? (
				<>
					<List className="navigationContainer">
						<ListItem className="listItem">
							<ThemeButton className="rent-btn" onClick={handleRentRelief} disableElevation>
								<Typography variant='button' className="rent-btn-text">{AuthService.checkToken() ? 'Get Rent Relief' : 'SIGN IN'}</Typography>
							</ThemeButton>
						</ListItem>
						{AuthService.checkToken() ? (
							<div>
								<Avatar
									// alt={userData.name}
									className="avatar"
									// component={RouterLink}
									// src={imageSource}
									// to="/profile"
									onClick={handleClick}
								>
									{(userData && userData.name && userData.name[0]) ? userData.name[0] : 'U'}
								</Avatar>
							</div>
						) : null}
				
						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
						>
							<React.Fragment>
								<MenuItem component={Link} to="/profile" onClick={handleClose}> {/* /profile/account */}
									<ListItemIcon className="listIcon">
										<AccountCircleIcon />
									</ListItemIcon>
									<ListItemText primary="My Profile"/>
								</MenuItem>
								{/* <MenuItem component={Link} to="/cart" onClick={handleClose}>
									<ListItemIcon className="listIcon">
									<Badge badgeContent={cartCount} color="secondary">
										<ShoppingCartIcon />
									</Badge>
									</ListItemIcon>
									<ListItemText primary="My Cart"/>
								</MenuItem> */}
								<MenuItem component={Link} to="/applications" onClick={handleClose}>
									<ListItemIcon className="listIcon">
										<PresentToAllIcon />
									</ListItemIcon>
									<ListItemText primary="My Applications"/>
								</MenuItem>
								<Divider />
								<MenuItem
									onClick={logoutUser}
								>
									<ListItemIcon className="listIcon">
										<ExitToAppIcon />
									</ListItemIcon>
									<ListItemText primary="Logout"/>
								</MenuItem>
							</React.Fragment>
						</Popover>

					</List>
				</>
			) : (
			<>
				<IconButton
					className="iconButton"
					onClick={onSidebarOpen}
					aria-label="Menu"
				>
					<MenuIcon />
				</IconButton>
				</>
			)}
		</Toolbar>
	);
};

Header.propTypes = {
	onSidebarOpen: PropTypes.func.isRequired,
	// pages: PropTypes.object.isRequired,
	// products: PropTypes.array.isRequired,
};

/* const mapDispatchToProps = (dispatch) => {
	return {
		updateCartCount: cartCount => dispatch(updateCartCount(cartCount)),
		onUserProfileUpdate: profile => dispatch(onUserProfileUpdate(profile)),
	};
};

const mapStateToProps = state => {
	return { cartCount: state.cartCount, profileData: state.profile };
}; */


export default Header;
