import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import './NotFoundStyle.scss';

/* const useStyles = makeStyles(theme => ({
	root: {},
	formContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
		maxWidth: 500,
		margin: `0 auto`,
	},
	section: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	label: {
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
})); */

const NotFound = () => {
	/* const classes = useStyles();

	const handleClick = () => {
		window.history.back();
	}; */

	return (
		<div className="not-found">
			<Typography variant='h5' align='center' className="title">
				There’s nothing here, but if you feel this is an error please
			</Typography>
			<Button
				size="large"
				variant="contained"
				color="primary"
				disableElevation
				// onClick={handleClick}
				href="/"
			>
				Go Back
			</Button>
		</div>
	);
};

export default NotFound;
