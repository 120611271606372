import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import '../AuthStyle.scss';

const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: true,
		length: {
			maximum: 300,
		},
	},
	password: {
		presence: { allowEmpty: false, message: 'is required' },
		length: {
			minimum: 8,
		},
	},
};

const SignInComponent = ({ loading, onLogin }) => {
  const navigate = useNavigate();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState(formState => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {},
		}));
	}, [formState.values]);

	const handleChange = event => {
		event.persist();

		setFormState(formState => ({
			...formState,
			values: {
				...formState.values,
				[event.target.name]:
					event.target.type === 'checkbox'
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...formState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (formState.isValid) {
			onLogin(formState.values);
		}

		setFormState(formState => ({
			...formState,
			touched: {
				...formState.touched,
				...formState.errors,
			},
		}));
	};

	/**
	 * Toogle password visiblity
	 */
	const handlePasswordVisibility = () => {
		setFormState({ ...formState, values: { ...formState.values,  showPassword: !formState.values.showPassword } });
	};

	const handleForgotPassword = (event) => {
		event.preventDefault();
		navigate('/forgot-password')
	};

	const hasError = field =>
		formState.touched[field] && formState.errors[field] ? true : false;

	return (
		<form name="sigin-form" className="sigin-form" method="post" onSubmit={handleSubmit}>
			<Helmet>
				<title>Rent Relief | Helping Americans With Rental Assistance</title>
				<meta name="description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
				<meta name="keywords" content="rent relief, rent relief for americans, help for rent relief, help for rent, refief from rent, how to get relief from rent, housing assistant, emergency rent relief, help to pay rent, covid rent refief,Rent Relief funds, rent assistance,apply for Rent Relief" />
				<meta property="og:title" content="Rent Relief | Helping Americans With Rental Assistance" />
				<meta property="og:description" content="Rent Relief is helping Americans with rental assistance for anyone experiencing financial hardship. " />
			</Helmet>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						placeholder="E-mail"
						label="E-mail"
						variant="outlined"
						size="medium"
						name="email"
						fullWidth
						helperText={hasError('email') ? formState.errors.email[0] : null}
						error={hasError('email')}
						onChange={handleChange}
						type="email"
						value={formState.values.email || ''}
						required
						autoComplete="email"
						autoFocus
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						placeholder="Password"
						label="Password"
						autoComplete="current-password"
						variant="outlined"
						size="medium"
						name="password"
						fullWidth
						helperText={
							hasError('password') ? formState.errors.password[0] : null
						}
						error={hasError('password')}
						onChange={handleChange}
						type={formState.values.showPassword ? 'text' : 'password'}
						value={formState.values.password || ''}
						required
						InputProps={{
							endAdornment: <InputAdornment position="end"><IconButton
								aria-label="toggle password visibility"
								edge="end"
								onClick={handlePasswordVisibility}
							>
								{formState.values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton></InputAdornment>,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<i>
						<Typography variant="subtitle2">
							Fields that are marked with * sign are required.
						</Typography>
					</i>
				</Grid>
				<Grid item xs={12} alignItems="space-between" className='action-btn-container'>
					<Link href='' onClick={handleForgotPassword}>Forgot password?</Link>
					<LoadingButton
						loading={loading}
						size="large"
						variant="contained"
						type="submit"
						color="primary"
						disabled={loading}
						disableElevation
					>
						Sign In
					</LoadingButton>
				</Grid>
			</Grid>
		</form>
	);
};

SignInComponent.propTypes = {
	loading: PropTypes.bool.isRequired,
	onLogin: PropTypes.func.isRequired,
};

export default SignInComponent;
