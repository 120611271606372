import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import { Header, Footer, Sidebar } from '../components/layout';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const MainLayout = () => {

	const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const [openSidebar, setOpenSidebar] = useState(false);

	const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isUpMd ? false : openSidebar;

	return (
		<>
			<Header className="reveal-from-bottom" onSidebarOpen={handleSidebarOpen} />
			<Sidebar
				onClose={handleSidebarClose}
				open={open}
				variant="temporary"
			/>
			<main className="site-content">
				<Outlet />
			</main>
			<Footer />
		</>
	)
};

export default MainLayout;